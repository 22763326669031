<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		LazyHydrate,
	},
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			loading: true,
			error: false,
			reviews: [],
		}
	},
	methods: {
		async setReviews() {
			try {
				const { data } = await this.$shopApi.get(`/google-place-details`, {
					query: {
						placeId: this.data.placeId,
						apiKey: this.data.apiKey, // is a client-side key
					},
				})
				this.reviews = data.placeDetails?.reviews
				if (!this.reviews?.length) this.error = true
			} catch (err) {
				this.error = true
			} finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.setReviews()
	},
}
</script>

<template>
	<Container class="px-md-8 px-3" v-if="!error">
		<section class="text-center">
			<v-row
				class="justify-center mx-1 my-t mb-4 align-center"
				:class="[
					($bem('__header'),
					{
						'mx-4': $b.m,
						'modern-row': data.titleStyle == 'modern',
					}),
				]"
			>
				<div class="col d-flex double-col pa-0 mr-2" v-if="data.titleStyle == 'double'">
					<div class="double"></div>
					<div class="double"></div>
				</div>
				<h2
					class="header d-block"
					:class="{
						modern: data.titleStyle == 'modern',
						short: data.titleStyle == 'short',
						'font-4': $b.td,
						'font-3': $b.m,
					}"
				>
					{{ data.title }}
				</h2>
				<div class="col d-flex double-col pa-0 ml-2" v-if="data.titleStyle == 'double'">
					<div class="double"></div>
					<div class="double"></div>
				</div>
			</v-row>

			<div class="d-flex align-center justify-center pa-12" v-if="loading">
				<v-progress-linear indeterminate color="primary" />
			</div>
			<div v-else>
				<LazyHydrate when-visible>
					<Carousel
						:dotContainerFullWidthMobile="true"
						:showDots="$b.mt"
						:arrowsBgHalfRounded="true"
						v-bind="{
							...$b.props({
								slidesPerPage: { m: 1, t: 3, d: 4 },
								showArrows: { d: true },
								center: { m: true },
								noDrag: { d: true },
							}),
							...this.$attrs,
						}"
						:id="'listId'"
					>
						<div class="d-flex justify-center py-2" v-for="(review, i) of reviews" :key="i">
							<v-card
								class="py-3 px-4 text-center mr-2 ml-1 d-flex flex-column align-center justify-center w100"
								min-height="250px"
							>
								<a :href="review.author_url" target="_blank" rel="noopener noreferrer">
									<Media
										aspect-ratio="1"
										class="mb-2"
										width="50px"
										height="50px"
										contain
										v-if="review.profile_photo_url"
										:src="review.profile_photo_url"
										:alt="`Google avatar de ${review.author_name}`"
										referrerpolicy="no-referrer"
									>
										<template #placeholder>
											<v-progress-circular
												size="30"
												:width="3"
												indeterminate
												color="primary"
											></v-progress-circular>
										</template>
									</Media>
								</a>
								<a style="color: black" target="_blank" class="name">{{ review.author_name }}</a>
								<div class="mb-2">
									<v-icon v-for="i of review.rating" :key="i" color="orange">mdi-star</v-icon>
									<v-icon v-if="review.rating < 5" color="orange">mdi-star-outline</v-icon>
								</div>
								<div class="comment pa-0 open-sans-family line-clamp-3">{{ review.text }}</div>
							</v-card>
						</div>
					</Carousel>
				</LazyHydrate>
			</div>
			<div class="mt-4" v-if="data.allReviewsUrl">
				<a target="_blank" :href="data.allReviewsUrl"> Ver todas las reseñas </a>
			</div>
		</section>
	</Container>
</template>

<style scoped>
.modern-row {
	border-bottom: 1px solid var(--primary);
}

@media (max-width: 599.98px) {
	.modern-row {
		flex-wrap: nowrap;
	}
}

h2.modern::after {
	content: '';
	border-bottom: 4px solid var(--headertext);
	position: relative;
	width: 102%;
	display: block;
	bottom: -1px;
}

h2.short::after {
	content: '';
	border-bottom: 3.4px solid var(--headertext);
	position: relative;
	width: 20%;
	display: block;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 6px;
}

.double-col {
	flex-direction: column;
}

.double {
	height: 1.4px;
	background: var(--headertext);
	margin: 1px 0;
}
</style>

