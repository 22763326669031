var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.error)?_c('Container',{staticClass:"px-md-8 px-3"},[_c('section',{staticClass:"text-center"},[_c('v-row',{staticClass:"justify-center mx-1 my-t mb-4 align-center",class:[
				(_vm.$bem('__header'),
				{
					'mx-4': _vm.$b.m,
					'modern-row': _vm.data.titleStyle == 'modern',
				}) ]},[(_vm.data.titleStyle == 'double')?_c('div',{staticClass:"col d-flex double-col pa-0 mr-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})]):_vm._e(),_c('h2',{staticClass:"header d-block",class:{
					modern: _vm.data.titleStyle == 'modern',
					short: _vm.data.titleStyle == 'short',
					'font-4': _vm.$b.td,
					'font-3': _vm.$b.m,
				}},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),(_vm.data.titleStyle == 'double')?_c('div',{staticClass:"col d-flex double-col pa-0 ml-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})]):_vm._e()]),(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center pa-12"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('Carousel',_vm._b({attrs:{"dotContainerFullWidthMobile":true,"showDots":_vm.$b.mt,"arrowsBgHalfRounded":true,"id":'listId'}},'Carousel',Object.assign({}, _vm.$b.props({
							slidesPerPage: { m: 1, t: 3, d: 4 },
							showArrows: { d: true },
							center: { m: true },
							noDrag: { d: true },
						}),
						this.$attrs),false),_vm._l((_vm.reviews),function(review,i){return _c('div',{key:i,staticClass:"d-flex justify-center py-2"},[_c('v-card',{staticClass:"py-3 px-4 text-center mr-2 ml-1 d-flex flex-column align-center justify-center w100",attrs:{"min-height":"250px"}},[_c('a',{attrs:{"href":review.author_url,"target":"_blank","rel":"noopener noreferrer"}},[(review.profile_photo_url)?_c('Media',{staticClass:"mb-2",attrs:{"aspect-ratio":"1","width":"50px","height":"50px","contain":"","src":review.profile_photo_url,"alt":("Google avatar de " + (review.author_name)),"referrerpolicy":"no-referrer"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-progress-circular',{attrs:{"size":"30","width":3,"indeterminate":"","color":"primary"}})]},proxy:true}],null,true)}):_vm._e()],1),_c('a',{staticClass:"name",staticStyle:{"color":"black"},attrs:{"target":"_blank"}},[_vm._v(_vm._s(review.author_name))]),_c('div',{staticClass:"mb-2"},[_vm._l((review.rating),function(i){return _c('v-icon',{key:i,attrs:{"color":"orange"}},[_vm._v("mdi-star")])}),(review.rating < 5)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-star-outline")]):_vm._e()],2),_c('div',{staticClass:"comment pa-0 open-sans-family line-clamp-3"},[_vm._v(_vm._s(review.text))])])],1)}),0)],1)],1),(_vm.data.allReviewsUrl)?_c('div',{staticClass:"mt-4"},[_c('a',{attrs:{"target":"_blank","href":_vm.data.allReviewsUrl}},[_vm._v(" Ver todas las reseñas ")])]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }