<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: { LazyHydrate },
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			slideIndex: 0,
		}
	},
	methods: {
		getBanner(index) {
			return this.data[`banner${index}`]
		},
		next() {
			this.$refs.carousel.$refs.ssrCarousel.next()
		},
		back() {
			this.$refs.carousel.$refs.ssrCarousel.back()
		},
	},
	computed: {
		carouselProps() {
			return {
				autoplayDelay: Number(this.data.autoplayDelay),
				loop: true,
				center: true,
				noDrag: this.$b.d,
			}
		},
	},
}
</script>

<template>
	<Container>
		<RatioBox width="100%" :aspectRatio="$b.d ? 1176 / 400 : 580 / 816">
			<div class="four-banners-plus-slide" :class="{ '--mobile': $b.m, '--tablet': $b.t }">
				<SafeLink class="banner1" :to="data.banner1Link">
					<Media :aspectRatio="282 / 152" width="100%" class="banner" :src="getBanner(1)" />
				</SafeLink>
				<SafeLink class="banner2" :to="data.banner2Link">
					<Media :aspectRatio="282 / 232" width="100%" class="banner" :src="getBanner(2)" />
				</SafeLink>
				<div class="center" style="position: relative">
					<LazyHydrate when-visible>
						<Carousel ref="carousel" v-bind="carouselProps" v-model="slideIndex" style="height: 100%">
							<SafeLink v-for="(slide, n) in data.slides" :key="n" :to="slide.link">
								<Media width="100%" class="banner" :src="slide.img" />
							</SafeLink>
						</Carousel>
					</LazyHydrate>
					<div class="__arrows" v-if="$b.d">
						<button class="__arrow" @click="back">
							<v-icon :color="$vars.secondarytext">mdi-chevron-left</v-icon>
						</button>
						<button class="__arrow" @click="next">
							<v-icon :color="$vars.secondarytext">mdi-chevron-right</v-icon>
						</button>
					</div>
					<div class="__dots">
						<div
							v-for="(_, n) in data.slides"
							:key="n"
							class="__dot"
							:class="{ '--active': slideIndex === n }"
						></div>
					</div>
				</div>
				<SafeLink class="banner3" :to="data.banner3Link">
					<Media :aspectRatio="282 / 232" width="100%" class="banner" :src="getBanner(3)" />
				</SafeLink>
				<SafeLink class="banner4" :to="data.banner4Link">
					<Media :aspectRatio="282 / 152" width="100%" class="banner" :src="getBanner(4)" />
				</SafeLink>
			</div>
		</RatioBox>
	</Container>
</template>

<style lang="scss" scoped>
.four-banners-plus-slide {
	& .__slides {
		position: relative;
	}
	& .__arrows {
		display: flex;
		justify-content: space-between;
		position: absolute;
		right: 0;
		bottom: 5px;
		width: auto;
		& .__arrow {
			margin: 5px;
			width: 36px;
			height: 36px;
			border: 1px solid var(--strongtext);
			background: var(--secondary);
			border-radius: 8px;
		}
	}
	& .__dots {
		width: 100%;
		position: absolute;
		display: flex;
		justify-content: flex-start;
		& .__dot {
			width: 40px;
			height: 5px;
			border-radius: 3px;
			background: var(--primary);
			margin: 5px;
			&.--active {
				background: var(--primarytext);
			}
		}
	}

	display: grid;
	height: 100%;
	place-content: center;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(5, 1fr);
	grid-column-gap: 1.36%;
	grid-row-gap: 4%;
	grid-template-areas:
		'banner1 center center banner3'
		'banner1 center center banner3'
		'banner2 center center banner3'
		'banner2 center center banner4'
		'banner2 center center banner4';

	& .banner1 {
		grid-area: banner1;
	}
	& .banner2 {
		grid-area: banner2;
	}
	& .banner3 {
		grid-area: banner3;
	}
	& .banner4 {
		grid-area: banner4;
	}

	& .center {
		grid-area: center;
	}

	&.--mobile {
		& .center {
			padding-bottom: 6px;
		}

		grid-template-columns: 1fr 1fr;
		grid-template-rows: 5fr repeat(5, 1fr);
		grid-row-gap: 1.96%;
		grid-column-gap: 2.75%;
		grid-template-areas:
			'center center'
			'banner1 banner3'
			'banner1 banner3'
			'banner2 banner3'
			'banner2 banner4'
			'banner2 banner4';
	}

	.banner ::v-deep img {
		border-radius: 8px;
	}
	::v-deep .ssr-carousel-track {
		padding: 0 !important;
	}
}
</style>

